.round(@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius: 	 @radius;
	border-radius: 	 @radius;
	
	-webkit-background-clip: padding-box;
	-moz-background-clip:    padding;
	background-clip: 	    padding-box;
}

.drop-shadow(@x: 0, @y: 0, @blur: 5px, @spread: 0, @alpha: .16) {
	-webkit-box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
	-moz-box-shadow: 	@x @y @blur @spread rgba(0, 0, 0, @alpha);
	box-shadow:			@x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.inner-shadow(@x: 0, @y: 0, @blur: 5px, @spread: 0, @alpha: .2) {
	-webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
	-moz-box-shadow: 	inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
	box-shadow:			inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.gradient (@startColor: #eee, @endColor: white) {
	background-color: @startColor;
	background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
	background: -webkit-linear-gradient(top, @startColor, @endColor);
	background: -moz-linear-gradient(top, @startColor, @endColor);
	background: -ms-linear-gradient(top, @startColor, @endColor);
	background: -o-linear-gradient(top, @startColor, @endColor);
}
.horizontal-gradient (@startColor: #eee, @endColor: white) {
 	background-color: @startColor;
	background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
	background-image: -webkit-linear-gradient(left, @startColor, @endColor);
	background-image: -moz-linear-gradient(left, @startColor, @endColor);
	background-image: -ms-linear-gradient(left, @startColor, @endColor);
	background-image: -o-linear-gradient(left, @startColor, @endColor);
}
.scale (@factor) {
	-webkit-transform: scale(@factor);
	-moz-transform: 	 scale(@factor);
	-ms-transform: 		 scale(@factor);
	-o-transform: 		 scale(@factor);
}
.rotate (@deg) {
	-webkit-transform: rotate(@deg);
	-moz-transform: 	 rotate(@deg);
	-ms-transform: 		 rotate(@deg);
	-o-transform: 		 rotate(@deg);
}
.box-shadow (@x: 0, @y: 0, @blur: 1px, @color: #000) {
	-webkit-box-shadow: @arguments;
	box-shadow: @arguments;
}

.text-shadow(@x: 0, @y: 0, @blur: 1px, @color: #000) {
     text-shadow: @arguments;
}

.transition(@property: color, @duration: .2s) {
	-webkit-transition: @property @duration ease-out;
	-moz-transition: @property @duration ease-out;
	-o-transition: @property @duration ease-out;
	transition: @property @duration ease-out;
}